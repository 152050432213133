import request from '@/utils/request'
// 预警测评记录列表
export function EvaluationWarningList(data) {
    return request({
      url: '/warning/page',
      method: 'post',
      data
    });
}
// 预警情况预警测评记录列表
export function getEvaluationWarningList(data) {
  return request({
    url: '/warning/situation',
    method: 'post',
    data
  });
}
// 获取答题记录
export function getAnswersList(params) {
    return request({
      url: '/result/answer',
      method: 'get',
      params
    });
}
// 根据量表id获取计划列表
export function getPlansList(params) {
    return request({
      url: '/warning/planList',
      method: 'get',
      params
    });
}
// 基本数据统计
export function getNumList(data) {
  return request({
    url: '/warning/ratio',
    method: 'post',
    data
  });
}
// 维度数据统计
export function getRatioList(data) {
  return request({
    url: '/warning/dimensionData',
    method: 'post',
    data
  });
}
// 获取预警报告
export function getWarningReport(params) {
  return request({
    url: '/warning/report',
    method: 'get',
    params
  });
}
// 获取预警报告
export function exportWarning(params) {
  return request({
    url: '/warning/export',
    method: 'get',
    params
  });
}