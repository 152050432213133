<template>
    <!-- 预警报告 -->
    <div class="viewReport" id="viewReport">
        <div class="region">
            <headerTopVue></headerTopVue>
            <div class="rg">
                <!-- 查看报告 -->
                <div class="topReport item">
                    <div class="left">
                        <span>{{ evaluationInfo.nickname }}的预警报告</span>
                    </div>
                    <div class="right">
                        <span>报告生成时间：{{ evaluationInfo.createTime }}</span>
                    </div>
                </div>
                <!-- 个人信息 -->
                <div class="userInfo item">
                    <p class="title">个人信息</p>
                    <p>姓名：<span>{{ evaluationInfo.nickname }}</span></p>
                    <p>性别：<span>{{ evaluationInfo.sex }}</span>
                        <!-- <span v-if="evaluationInfo.sex === 1">男</span>
                        <span v-else-if="evaluationInfo.sex === 2">女</span>
                        <span v-else-if="evaluationInfo.sex === 3">未设置</span> -->
                    </p>
                    <p>出生日期：<span v-if="evaluationInfo.birthday">{{ evaluationInfo.birthday.slice(0, 10) }}</span></p>
                    <p>年龄：<span>{{ evaluationInfo.age }}</span></p>
                    <p>电话：<span>{{ evaluationInfo.telephone }}</span></p>
                </div>
                <div class="EvaluationInfo item">
                    <P class="title">
                        测评信息
                    </P>
                    <p>计划名称：<span>{{ evaluationInfo.planName }}</span></p>
                    <p>量表名称：<span>{{ evaluationInfo.gaugeName }}</span></p>
                    <p>测评时间：<span>{{ evaluationInfo.evaluationTime }}</span></p>

                </div>
                <!-- <div class="showIcon item">
                    <p class="title">预警数据</p>
                    <div class="iconContent" id="iconContent" v-if="iconType"></div>
                    <span v-else>未设置图表</span>
                </div> -->
                <div class="excel item">
                    <p class="title">预警数据</p>
                    <div class="excelContent">
                        <el-table :data="warningList" style="width: 100%" border
                            :header-cell-style="{ background: ' #F9F9F9' }" size="small">
                            <el-table-column label="维度名称">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.dimensionName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="维度得分">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.score }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="预警名称" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.warningName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="预警级别">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.level }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="预警范围">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.formula }}</span>
                                    <!-- <span>{{ scope.row.minRange }}</span>
                                    <span v-if="scope.row.firstSymbol === 1">＜</span>
                                    <span v-else-if="scope.row.firstSymbol === 2">≤</span>
                                    <span> X </span>
                                    <span v-if="scope.row.firstSymbol === 1">＜</span>
                                    <span v-else-if="scope.row.firstSymbol === 2">≤</span>
                                    <span>{{ scope.row.maxRange }}</span> -->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="excel item">
                    <p class="title">答题数据</p>
                    <div class="excelContent">
                        <el-table :data="tableData" style="width: 100%" border
                            :header-cell-style="{ background: ' #F9F9F9' }" size="small">
                            <el-table-column label="题目序号" width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.orderNum }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="题目内容">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="答案">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.answerName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="得分" width="80">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.score }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="item">
                    <p class="title">咨询师评语</p>
                    <p>
                        <span>无</span>
                    </p>
                </div>
                <!-- <p>结果声明：本测试结果只反映受测者当时的状态，内容仅供参考，如有疑问，请咨询专业心理咨询师。</p> -->
                <p>{{ $store.state.comment }}</p>
                <BottomVue></BottomVue>
                <div class="btn">
                    <span @click="back">返回</span>
                    <span class="export" @click="ExportWord">导出</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import JSZipUtils from "jszip-utils"
import docxtemplater from "docxtemplater"
import { saveAs } from 'file-saver'
import PizZip from 'pizzip';
import html2canvas from 'html2canvas';

import { getReport } from '@/api/evaluationManagement/result'
import { getWarningReport } from '@/api/dangerWarning/warning'
import BottomVue from "@/components/Bottom.vue";
import headerTopVue from "@/components/headerTop.vue";
export default {
    data() {
        return {
            evaluationInfo: {
                age: null,
                birthday: null,
                date: null,
                sex: null,
                telephone: null,
                birthday: null,
                nickname: null,
                memo: null,
                dimensionNormVOMap: []
            },
            tableData: [],
            warningList: [],
            iconType: 1,
            list: [],
            xList: [], // x轴的数组
            yList: []  //y轴的数组
        }
    },
    methods: {
        // 点击返回
        back() {
            // 根据不同的路由返回不同的导航
            // console.log(this.$route.path);
            if (this.$route.path === '/home/warningReport') {
                this.$router.push({
                path: '/home/personalWarning',
                query: {
                    id: this.$route.query.gaugeId
                }
        
            })
            } else if (this.$route.path === '/home/fileDetails3') {
                this.$router.push({
                path: '/home/healthRecords/record',
                // query: {
                //     id: this.$route.query.gaugeId
                // }
            })
                
            }
          
        },
        async WarningReport(val) {
            const res = await getWarningReport({ id: val });
            console.log(res);
            if (res.code === 200) {
                this.evaluationInfo = res.data;
                this.warningList = res.data.warningDataVOList;
                this.tableData = res.data.answerDataVOList;
                this.warningList = this.$utils.ToType(this.warningList);

            }
            if (this.evaluationInfo.sex === 1) {
                this.evaluationInfo.sex = '男'
            } else if (this.evaluationInfo.sex === 2) {
                this.evaluationInfo.sex = '女'
            } else if (this.evaluationInfo.sex === 3) {
                this.evaluationInfo.sex = '未设置'
            }
            this.warningList.forEach(item => {
                item.formula = '';
                if (item.type === 1 ) {
                    console.log(11111)
                    if (item.firstSymbol === 1) {
                        item.firstSymbol = '<'
                    } else if (item.firstSymbol === 2) {
                        item.firstSymbol = '≤'
                    }
                    if (item.secondSymbol === 1) {
                        item.secondSymbol = '<'
                    } else if (item.secondSymbol === 2) {
                        item.secondSymbol = '≤'
                    }
                    if (item.minRange !== null && item.maxRange !== null) {
                        item.formula = `${item.minRange}${item.firstSymbol}X${item.secondSymbol}${item.maxRange}`
                        
                    }
                } else {
                    item.formula = `${item.symbol} ${item.count}`
                }


            })
        },
        echartsInit() {
            console.log(this.xList);
            let option = {};
            if (this.type === 1) {
                option = {
                    title: {
                        text: "图表显示"
                    },
                    tooltip: {},
                    xAxis: {
                    },
                    yAxis: { data: this.yList },
                    series: [{
                        name: '分值',
                        type: 'bar',
                        data: this.xList,
                        color: '#5b87a3'
                    }]
                };
            } else if (this.type === 2) {
                // icon = 'line'
                option = {
                    title: {
                        text: "图表显示"
                    },
                    tooltip: {},
                    xAxis: {

                    },
                    yAxis: { data: this.yList },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.xList,
                        color: '#5b87a3'
                    }]
                };
            } else if (this.type === 3) {
                // icon = 'pie'
                // option = {
                //     series: [
                //         {
                //             type: 'pie',
                //             data: this.list
                //         }
                //     ]
                // };
                option = {
                    title: {
                        text: "图表显示"
                    },
                    tooltip: {},
                    xAxis: {
                    },
                    yAxis: { data: this.yList },
                    series: [{
                        name: '分值',
                        type: 'line',
                        data: this.xList,
                        color: '#5b87a3',
                        areaStyle: {}
                    }]
                };
            }
            let myEchart = this.$echarts.init(document.getElementById("iconContent"));

            myEchart.setOption(option);
        },
        // 实现导出页面为图片
        // ---------------分割线-------------------
        dataURLToBlob(dataurl) {//ie 图片转格式
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime })
        },
        downloadResult(name) {
            let canvasID = document.getElementById('viewReport');
            let a = document.createElement('a');
            html2canvas(canvasID).then(canvas => {
                let dom = document.body.appendChild(canvas);
                dom.style.display = "none";
                a.style.display = "none";
                document.body.removeChild(dom);
                let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
                a.setAttribute("href", URL.createObjectURL(blob));
                a.setAttribute("download", name + ".png")
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(blob);
                document.body.removeChild(a);
            });
        },
        // 点击导出
        printOut() {
            let name = `${this.evaluationInfo.nickname} -预警报告`
            // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
            // $(window).scrollTop(0); // jQuery 的方法
            document.body.scrollTop = 0; // IE的
            document.documentElement.scrollTop = 0; // 其他
            this.downloadResult(name)
        },
        // ---------------分割线-------------------
        // 导出word
        ExportWord() {
            //这里要引入处理图片的插件，下载docxtemplater后，引入的就在其中了
            var ImageModule = require('docxtemplater-image-module-free');
            var _this = this;
            //Word路径，在public文件下(CLI3)
            JSZipUtils.getBinaryContent("warningImport.docx", function (error, content) {
                if (error) {
                    throw error
                };
                let opts = {}
                opts.centered = true;
                opts.fileType = "docx";
                opts.getImage = (tag) => {
                    return _this.base64DataURLToArrayBuffer(tag);
                }
                opts.getSize = () => {
                    return [600, 400]//这里可更改输出的图片宽和高
                }
                let zip = new PizZip(content);
                let doc = new docxtemplater();
                doc.attachModule(new ImageModule(opts));
                doc.loadZip(zip);
                doc.setData({
                    ..._this.evaluationInfo, //基础信息
                    warningList: _this.warningList, // 预警数据
                    arr: _this.tableData // 答题数据
                });
                try {
                    doc.render()
                } catch (error) {
                    var e = {
                        message: error.message,
                        name: error.name,
                        stack: error.stack,
                        properties: error.properties,
                    }
                    console.log(JSON.stringify({
                        error: e
                    }));
                    throw error;
                }
                var out = doc.getZip().generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                })
                saveAs(out, `${_this.evaluationInfo.nickname}的预警报告.docx`)
            })
        },
        //这里是处理图片的代码
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }
    },
    created() {
        this.WarningReport(this.$route.query.id);
        //  this.getList(Number(sessionStorage.getItem('ReportId')));

    },
    components: {
        headerTopVue, BottomVue
    }
}
</script>

<style lang="scss" scoped>
.viewReport {

    .region {
        background: white;
        margin: auto;

        .item {
            margin-bottom: 30px;
        }

        .btn {
            display: flex;
            align-items: center;
            height: 40px;
            width: 100%;
            background: var(--btn-color);
            border-radius: 4px 0px 0px 4px;
            color: white;

            span {
                display: inline-block;
                width: 50%;
                box-sizing: border-box;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                // line-height: 100%;
            }

            .export {
                border-left: 1px solid #FFFFFF;
            }
        }

        .showIcon {
            .iconContent {
                height: 400px;
                width: 100%;
                // background: #028DA9;
            }
        }

        .excel {
            .excelContent {
                ::v-deep .el-table .cell {
                    font-size: 14px !important;
                }
            }
        }

        .rg {
            .topReport {
                // width: 650px;
                padding: 0px 10px;
                height: 40px;
                background: var(--btn-color);
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    color: white;
                    font-size: 14px;
                    line-height: 40px;
                }

                .left,
                .right {
                    // line-height: 40px;
                    height: 40px;
                }
            }
        }

        .title {
            font-weight: 600;
            font-size: 16px;
        }

        p {
            font-size: 14px;
            color: #303030;

            span {
                color: #7D7D7D;
                ;
            }
        }
    }
}

@media screen and (max-width: 2160PX)and (min-width: 751PX) {
    .region {
        width: 900px;

        .rg {
            padding: 40px 100px;

            .topReport {
                width: 650px;
                // height: 40px;
                // background: #028DA9;
            }
        }

        // background: white;

    }
}

@media screen and (max-width: 750PX) {
    .region {
        width: 100%;

        .rg {
            padding-top: 20px;
            // padding: 20px 20px;
            width: 90%;
            margin: auto;

            // .topReport {
            //     // width: 100%;
            //     // margin: auto;
            //     // width: 90%;
            //     // height: 40px;
            //     // background: #028DA9;
            // }
        }

        // background: white;

    }
}
</style>